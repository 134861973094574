import React, { useState, useEffect } from 'react';
function App() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const difference = +new Date("2024-12-31") - +new Date();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  }

  return (
    <div className="app-container">
      <div className="maintenance-container">
        <h1>Website Maintenance in Progress</h1>
        <p>We're working hard to make things better. Please check back later.</p>
        
      </div>

      <footer className="footer-container">
        <div className="footer-column">
          <h4>Legal & Policies</h4>
          <ul>
            <li><a href="/terms-of-service.html" target="_blank" rel="noopener noreferrer" aria-label="Open Terms of Service in a new tab">Terms of Service</a>
            </li>
            <li><a href="/privacy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
            <li><a href="/cookies.html" target="_blank" rel="noopener noreferrer">Cookie Policy</a></li>
            <li><a href="/disclaimer.html" target="_blank" rel="noopener noreferrer">Disclaimer</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Company Info</h4>
          <ul>
            <li>© 2024 Johnny Nel Global Growth Partnership</li>
            <li>Services provided through:</li>
            <li>PRIXGIG PTY Limited (South Africa)</li>
            <li>PRIXGIG LLC (USA)</li>
            <li>Associated entities worldwide</li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Connect</h4>
          <div className="social-links">
            <a href="mailto:info@prixgig.com" target="_blank" rel="noopener noreferrer">📧 info@prixgig.com</a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          </div>
        </div>
      </footer>

      <div className="bottom-bar">
        <p className="bottom-bar-text">
          Johnny Nel provides growth partnership services globally. The specific entity engaged for services may vary based on client location and project requirements.
        </p>
      </div>
    </div>
  );
}

export default App;